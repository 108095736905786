import request from '@/utils/request'

const ding_ding_userApi = {
  ding_ding_user_list: '/ding_ding_user/',
  ding_ding_user_create: '/ding_ding_user/',
  ding_ding_user_update: '/ding_ding_user/',
  ding_ding_user_delete: '/ding_ding_user/',
  ding_ding_dept_list: '/ding_ding_dept/',
  ding_ding_dept_create: '/ding_ding_dept/',
  ding_ding_dept_update: '/ding_ding_dept/',
  ding_ding_dept_delete: '/ding_ding_dept/'
}

/**
 * 列表
 */
export function ding_ding_user_list (parameter) {
  return request({
    url: ding_ding_userApi.ding_ding_user_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function ding_ding_user_create (parameter) {
  return request({
    url: ding_ding_userApi.ding_ding_user_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function ding_ding_user_delete (ding_ding_user_id) {
  return request({
    url: ding_ding_userApi.ding_ding_user_delete + ding_ding_user_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function ding_ding_dept_list (parameter) {
  return request({
    url: ding_ding_userApi.ding_ding_dept_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function ding_ding_dept_create (parameter) {
  return request({
    url: ding_ding_userApi.ding_ding_dept_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function ding_ding_dept_delete (ding_ding_dept_id) {
  return request({
    url: ding_ding_userApi.ding_ding_dept_delete + ding_ding_dept_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
